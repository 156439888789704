<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <p class="title">Consumbles</p>
      <p class="subtitle">To change values, add a new version</p>
      <div class="field is-horizontal is-pulled-right">
        <div class="field-label is-normal">
          <label class="label">Version</label>
        </div>
        <div class="field-body">
          <div class="field has-addons has-addons-right">
            <div class="control">
              <div class="select">
                <!--<select v-model="selectedIndex" @change="getSelectedVersionData()">-->
                <select v-model="selectedIndex">
                  <option
                    v-for="version in versionList"
                    :key="version"
                    :value="version">{{ version }}</option>
                </select>
              </div>
            </div>
            <div class="control">
              <button
                class="button is-primary tooltip"
                :disabled="isEditValues"
                @click="addNewList()"
                data-tooltip="Add new values">
                <span class="icon">
                  <i class="mdi mdi-plus mdi-24px" />
                </span>
              </button>
            </div>
            <div class="control ml-2">
              <button v-if="!isEditValues"
                class="button is-primary tooltip"
                @click="editValues(isEditValues)"
                data-tooltip="Click to Edit Values">
                <span class="icon is-small">
                  <i class="mdi mdi-lead-pencil mdi-24px" />
                </span>
              </button>
              <button v-else
                class="button is-danger tooltip"
                :disabled="isError.isError"
                @click="editValues(isEditValues)"
                data-tooltip="Cancel Edit">
                <span class="icon is-small">
                  <i class="mdi mdi-close mdi-24px" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Description</th>
            <th>Category</th>
            <th class="has-text-right">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(consumable) in selectedList"
            :key="consumable.consumablesId">
            <td>{{ consumable.description }}</td>
            <td>{{ consumable.category }}</td>
            <td v-if="isEditValues">
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      :class="{ 'is-danger' : isEditValues && consumable.value <= 0 }"
                      v-model="consumable.value"
                      :min="0"
                      :precision="2" />
                    <span v-if="isEditValues && consumable.value <= 0"
                      class="help is-danger">{{ errorMessage }}</span>
                  </div>
                </div>
              </div>
            </td>
            <td
              v-if="!isEditValues"
              class="has-text-right">{{ consumable.value.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </article>
  </div>
</template>

<script>
import VueNumeric from '@/components/VueNumeric'
import Guid from '@/components/Guid'

export default {
  name: 'InsurerConsumables',
  components: {
    VueNumeric
  },
  props: {
    entity: null,
    isEditValues: Boolean,
    isError: null
  },
  data() {
    return {
      selectedIndex: 1,
      errorMessage: 'Value cannot be 0 or negative'
    }
  },
  computed: {
    $v() {
      return this.$parent.$v
    },
    versionList() {
      if (!this.entity.insurerConsumables) {
        return []
      }
      var versionList = []
      this.entity.insurerConsumables.forEach(function(el) {
        var version = el.versionNo
        if (versionList.length === 0) {
          versionList.push(version)
        } else if (versionList.indexOf(version) === -1) {
          versionList.push(version)
        }
      }, this)
      return versionList.sort()
    },
    selectedList: {
      get: function() {
        if (!this.entity.insurerConsumables) {
          return []
        }
        var versionFilter = function(obj) {
          if (this === obj.versionNo) return obj
        }
        return this.entity.insurerConsumables.filter(versionFilter, this.selectedIndex)
      },
      set: function(newValue) {}
    }
  },
  watch: {
    selectedList: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.checkError()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.selectedIndex = Math.max(...this.versionList)
  },
  methods: {
    // onNativeInput(index, event) {
    //   const location = ((this.selectedIndex - 1) * 5) + index
    //   this.$parent.$v.entity.insurerConsumables.$each[location].value.$touch()
    //   this.entity.insurerConsumables[location].isDirty = true
    // },
    getVersionList() {
      var versionList = []
      this.entity.insurerConsumables.forEach(function(el) {
        var version = el.versionNo
        if (versionList.length === 0) {
          versionList.push(version)
        } else if (versionList.indexOf(version) === -1) {
          versionList.push(version)
        }
      }, this)
      this.versionList = versionList.sort()
    },
    getSelectedVersionData() {
      var versionFilter = function(obj) {
        if (this === obj.versionNo) return obj
      }
      this.selectedList = this.entity.insurerConsumables.filter(versionFilter, this.selectedIndex)
    },
    // addNewList() {
    //   const newVersion = Math.max(...this.versionList) + 1
    //   this.versionList.push(newVersion)
    //   var newList = JSON.parse(JSON.stringify(this.selectedList))
    //   newList.forEach(item => {
    //     item.value = 0
    //     item.versionNo = newVersion
    //     item.consumablesId = Guid.newGuid()
    //     item.isNew = true
    //     this.entity.insurerConsumables.push(item)
    //   })
    //   this.selectedList = newList
    //   this.selectedIndex = newVersion
    // },
    addNewList() {
      // Get Max and New Version
      const maxVersion = this.getMaximumVersion()
      const newVersion = Math.max(...this.versionList) + 1

      // Get Previous Version Entity
      let prevEntity = this.entity.insurerConsumables.filter((i) => i.versionNo === newVersion - 1)

      // Check if newVersion < maxVersion (i.e add versions to match the maxVersion)
      if (newVersion < maxVersion) {
        // Get Previous Version Entity
        // let prevEntity = this.entity.insurerConsumables.filter((i) => i.versionNo === newVersion - 1)

        if (prevEntity) {
          // Add Previous Version Entity to versions below Max Version
          for (var i = newVersion; i <= maxVersion; i++) {
            this.versionList.push(i)
            var newList = JSON.parse(JSON.stringify(prevEntity))
            newList.forEach(item => {
              item.versionNo = i
              item.consumablesId = Guid.newGuid()
              item.isNew = true
              this.entity.insurerConsumables.push(item)
            })

            this.selectedList = newList
            this.selectedIndex = maxVersion
          }

          //Finally add New Version (i.e max version)
          // this.versionList.push(maxVersion)
          // var newList = JSON.parse(JSON.stringify(this.selectedList))
          // newList.forEach(item => {
          // item.value = 0
          // item.versionNo = maxVersion
          // item.paintTypeId = Guid.newGuid()
          // item.isNew = true
          // this.entity.insurerConsumables.push(item)
          // })
          // this.selectedList = newList
          // this.selectedIndex = maxVersion
          this.$emit('add-versions', maxVersion)
        }
      }
      else {
        // if newVersion is same as max version or greater than max version
        // simply add new version
        this.versionList.push(newVersion)
        var newList = JSON.parse(JSON.stringify(prevEntity))
        newList.forEach(item => {
          item.versionNo = newVersion
          item.consumablesId = Guid.newGuid()
          item.isNew = true
          this.entity.insurerConsumables.push(item)
        })
        this.selectedList = newList
        this.selectedIndex = newVersion

        // Add previous versions to other consumables
        // if they are behind the max version
        // this will put all the consumables to same version
        this.$emit('add-versions', newVersion)
      }
    },

    // Get Maximum values from all the Values
    getMaximumVersion() {
      let value = [
        Math.max.apply(Math, this.entity.insurerConsumables.map(function (c) { return c.versionNo })),
        Math.max.apply(Math, this.entity.insurerPaintMaterials.map(function (c) { return c.versionNo })),
        Math.max.apply(Math, this.entity.insurerLoadingValues.map(function (c) { return c.versionNo })),
        Math.max.apply(Math, this.entity.insurerSpecialRates.map(function (c) { return c.versionNo }))
      ]

      return Math.max.apply(Math, value)
    },

    // Edit Consumable Values
    editValues(value) {
      // Can edit all the values
      this.$emit('on-edit', value)
    },

    checkError() {
      // const maxVersion = this.getMaximumVersion()

      // const check = this.entity.insurerConsumables.find((i) => i.versionNo === maxVersion && i.value <= 0.00)

      // if (check) {
      //   this.$emit('set-error',  { isError: true , CMI: true, PMI: false, LVI: false, SPI: false })
      // }
      // else {
      //   this.$emit('set-error',  { isError: false , CMI: true, PMI: false, LVI: false, SPI: false })
      // }
      this.$emit('set-error')
    }
  }
}
</script>

<style lang="scss">
</style>
